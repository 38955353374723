<template>
  <div class="profile-board">
    <h2 v-if="loading.recomendations">Загрузка...</h2>
    <h2 v-else-if="!recOriginal">Нет данных</h2>
    <div v-else class="row">
      <div class="col d-flex align-center">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-if="recOriginal" class="no-caps"><b>Оригинальный отчет</b></v-tab>
          <v-tab v-if="recEditable && $store.getters['auth/isAllowed']('view_edited_recommendation')" class="no-caps">Отредактированный отчет</v-tab>
        </v-tabs>
      </div>
    </div>

    <div v-if="recOriginal" class="row">
      <div class="col d-flex align-center">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="recOriginal">
            <RecomendationReport :recs="recOriginal" @refresh="refresh" @loadPdf="loadPdf('original', $event)"/>
          </v-tab-item>
          <v-tab-item>
            <RecomendationReport :recs="recEditable" @refresh="refresh" isEditable @editItem="onEdit" @loadPdf="loadPdf('editable', $event)" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <CheckListEditDialog v-model="showEditDialog" :sourceData="editedRec" @save="saveEdited" title="Редактирование рекомендаций" />

  </div>
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters } from 'vuex';
import RecomendationReport from '@/components/recomendations/RecomendationReport';
import CheckListEditDialog from './CheckListEditDialog';
import { CANDIDATE } from '@/common/constants/userTypes';

export default {
  components: {
    RecomendationReport, CheckListEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      editedRec: null,
      tab: 0,
      tabMain: 0
    };
  },
  computed: {
    ...mapGetters({
      recomendations: 'users/candidate/recomendations',
      loading: 'users/candidate/loading',
      errors: 'users/candidate/errors',
      user: 'auth/user'
    }),
    recOriginal() {
      return this.recomendations?.psychotype_recommendations?.original ?? null;
    },
    recEditable() {
        return this.recomendations?.psychotype_recommendations?.editable ?? null;
    },
    candidateId() {
      return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
    }
  },
  methods: {
    ...mapActions({
      loadCandidateRecomendations: 'users/candidate/loadCandidateRecomendations',
      refreshCandidateRecomendations: 'users/candidate/refreshCandidateRecomendations',
      loadCandidateRecomendationsPdf: 'users/candidate/loadCandidateRecomendationsPdf',
      editRecomendation: 'users/candidate/editRecomendation',
    }),
    async loadPdf(report_type, logo) {
      const data = {
        candidate: this.candidateId,
        report_type,
        logo,
        target: 1
      };
      const resp = await this.loadCandidateRecomendationsPdf(data);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async refresh() {
      const response = await this.refreshCandidateRecomendations({
        candidate: this.candidateId,
        target: 1
      });
      if (!response) {
        console.log(this.errors);
        if(this.errors?.recomendations?.detail)
          this.$toast.error(this.errors.recomendations.detail, '', {position: 'topRight'})
      }
    },
    downloadFile(fileLink) {
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', fileLink);
      // link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onEdit(rec) {
      this.editedRec = rec;
      this.showEditDialog = true;
    },
    saveEdited(rec) {
      const response  = this.editRecomendation({
        id: this.editedRec.id,
        title: rec.title,
        text: rec.text.join("\n")
      })
      if (response) {
        this.showEditDialog = false;
        this.loadCandidateRecomendations( {
          candidate: this.candidateId,
          target: 1
        }) 
      }
    }

  },

  async mounted() {
    await this.loadCandidateRecomendations( {
        candidate: this.candidateId,
        target: 1
    }) 

  }
};
</script>
  