<template>
  <div v-if="show">
    <v-overlay :value="show" class="" @click="show = false">
      <iframe v-if="videoLink"
        class="video-frame"
        :src="videoLink"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      >
      </iframe>
      <h2 v-else>Неверный формат ссылки на видео</h2>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Video',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Boolean,
    link: {
      type: String
      // required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    videoLink() {
      // https://www.youtube.com/embed/a43UqD3w5yg?si=35AD_ywsokyJb1gr

      const regexp = /https:\/\/youtu.be\/(.*)?si=(.*)/m;
      const res = this.link.match(regexp);
      let link = '';
      if (res && res.length > 1) {
        link = `https://www.youtube.com/embed/${res[1]}`;
      }
      if (res && res.length > 2) {
        link += `?si=${res[2]}`;
      }
      return link;
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.video-frame {
  // width: 560px;
  // height: 315px;
  width: 840px;
  height: 472px;
  max-width: 100vw;
}

</style>
