<template>
  <v-dialog v-model="dialogEditShow" max-width="700px">
    <v-card class="pa-4 pa-sm-8">
      <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 class="text-center">{{title}}</h2>
      <form v-if="sourceData" @submit.prevent="editClientConfirm" class="mt-6">
        <div class="container">
          <v-text-field label="Заголовок" v-model="editedItem.title" :error-messages="titleErrors" outlined dense />
          <h4 class="mb-2">Рекомендации:</h4>
          <div v-if="!editedItem.text.length" class="mt-2 mb-3">Список рекомендаций пуст</div>
          <div v-for="(t, ind) in editedItem.text" :key="ind">
            <v-textarea v-model="editedItem.text[ind]" outlined dense auto-grow rows="2">
              <template v-slot:append-outer>
                <v-btn class="ml-3" text icon small @click="onDelete(ind)">
                  <v-icon class="_mb-1">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </div>
          <v-btn color="primary" dark fab small @click="onAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <div v-for="(err, ind) in errors.edit" :key="ind" class="error--text">
            <template v-if="!editedItem.hasOwnProperty(err[0])">
              <div v-for="(e, i) in err[1]" :key="i">{{ e }}</div>
            </template>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary" :loading="loading.edit" :disabled="loading.edit"> Сохранить </v-btn>
          </div>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';

export default {
  model: {
     prop: 'value',
     event: 'input'
  },
  props: {
    value: Boolean,
    sourceData: Object,
    title: String,
  },
  validations: {
    editedItem: {
      title: { required }
    }
  },
  data: () => ({
    editedItem: {
      id: 0,
      title: '',
      text: []
    }
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
        this.editedItem.title = this.sourceData.title;
        this.editedItem.text = [...this.sourceData.text];
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'users/candidate/loading',
      errors: 'users/candidate/errors',
    }),
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        if (!val) {
          this.$v.$reset();
        }
        this.$emit('input', false);
      }
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.editedItem.title.$dirty) return errors;
      if (!this.$v.editedItem.title.required) errors.push(MESSAGES.REQUIRED);
      //   return this.errors.edit.find(err => err[0] === 'title')?.[1] ?? errors;
    }
  },
  methods: {
    ...mapActions({
      clearError: 'users/clients/clearError',
      saveClient: 'users/clients/saveClient',
      loadPartners: 'users/partners/loadPartners'
    }),
    onInput() {
      if (this.errors.edit.length) this.clearError();
    },
    onDelete(ind) {
      this.editedItem.text.splice(ind, 1);
    },
    onAdd() {
      this.editedItem.text.push('');
    },
    editClientConfirm() {
      this.$v.$touch();
      if (this.$v.$invalid) 
        return;
      this.$emit('save', this.editedItem)
      //     const form = {
      //       ...this.editedItem,
      //       supervisor: this.editedItem.partnerId
      //     };
      //     this.saveClient(form).then(() => {
      //       this.dialogEditShow = false;
      //     });
      //   }
    }
  }
};
</script>
