<template>
  <div v-if="results && teamRoles && teamRoles.length">
    <div class="row mt-2 mt-md-8">
      <div class="col-12 pb-0">
        <h3 class="page-title">Роли в команде</h3>
      </div>

      <div class="col-md-6 py-0">
        <RadarChart :key="radarKey" :data="chartdata" />
      </div>

      <div class="col-md-5 offset-md-1">
        <div class="role-results">
          <div class="role-results__item page-description" v-for="role in teamRoles" :key="role.id">
            <span class="font-weight-600">{{role.title}}</span>
            <span>{{role.points}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-8">
      <div class="col-12">
        <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
          <h3 class="page-title">Характеристики каждой роли</h3>
          <span class="pointer page-description" @click="toggleAllShow">
          {{listsIsOpen ? 'Скрыть все' : 'Раскрыть все'}}
        </span>
        </div>
      </div>

      <div class="col-md-5 py-0">
        <v-expansion-panels v-model="show1" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics1" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <img class="mr-3 icon-sm" src="@/assets/images/icons/rhombus.svg" :alt="item.title">
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="col-md-5 offset-md-2 py-0">
        <v-expansion-panels v-model="show2" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics2" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <img class="mr-3 icon-sm" src="@/assets/images/icons/rhombus.svg" :alt="item.title">
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import RadarChart from '@/components/ui/RadarChart';
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

export default {
  name: 'TeamRoles',
  components: {
    RadarChart,
  },
  data: () => ({
    radarKey: 0,
    show1: [],
    show2: [],
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    teamRoles() {
      const roles = this.results.team_roles_normalized?.team_roles?.team_roles;
      if (roles) {
        return [...roles].sort((a, b) => b.points - a.points);
      }
      return roles;
    },
    chartdata() {
      return {
        labels: this.teamRoles.map((r) => r.title),
        datasets: [
          {
            label: 'Результат',
            backgroundColor: 'rgba(24,160,251, 0.7)',
            pointBackgroundColor: 'rgba(24,160,251)',
            pointRadius: 5,
            data: this.generateDate(),
          },
        ],
      };
    },
    characteristics1() {
      const count = Math.ceil(this.teamRoles.length / 2);
      return this.teamRoles.slice(0, count);
    },
    characteristics2() {
      const count = Math.ceil(this.teamRoles.length / 2);
      return this.teamRoles.slice(count);
    },
    listsIsOpen() {
      return this.show1.length || this.show2.length;
    },
  },
  mounted() {
    window.addEventListener('resize', this.radarKeyIncrement);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.radarKeyIncrement);
  },
  methods: {
    radarKeyIncrement: throttle(function () {
      this.radarKey += 1;
    }, 1000),
    generateDate() {
      const result = [];
      this.teamRoles.forEach((role) => {
        if (!role.points) {
          if (!result.some((r) => !r)) {
            result.push(role.points);
          } else {
            result.push(null);
          }
        } else {
          result.push(role.points);
        }
      });
      return result;
    },
    toggleAllShow() {
      if (this.listsIsOpen) {
        this.show1 = [];
        this.show2 = [];
      } else {
        this.show1 = this.characteristics1.map((k, i) => i);
        this.show2 = this.characteristics2.map((k, i) => i);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.role-results {
  background: #F5F8FF;
  border-radius: 0.25rem;
  border: 1px solid $color-blue;
  padding: 1.5rem;
  @media (min-width: $break-point-md) {
    padding: 2rem;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-light-gray;
    padding: .75rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}
</style>
