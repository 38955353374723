<template>
    <div class="row">
        <div class="col-md-4">
          <PieChart :data="chartdata" />
        </div>
        <div class="col-md-8">
          <div class="pb-6 border-bottom" v-if="candidatePosition">
            <div class="position-candidate">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <div class="color-box mr-4" :style="{background: candidatePosition.color}" />
                  <div class="font-weight-600 title">{{candidatePosition.name}}</div>
                </div>
                <div class="position-candidate__number">{{candidatePosition.value}}%</div>
              </div>
              <div>
                <v-icon right class="ma-0 mr-3 icon-sm" color="primary">mdi-alert-circle</v-icon>
                Должность, на которую тестировался кандидат
              </div>
            </div>
          </div>

          <div class="mt-6 legend">
            <div class="legend__item" v-for="item in filteredPositions" :key="item.name">
              <div class="d-flex align-center">
                <div class="color-box mr-4" :style="{background: item.color}"/>
                <div>{{item.name}}</div>
              </div>
              <div>{{item.value}}%</div>
            </div>
          </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PieChart from '@/components/ui/PieChart';

const colors = ['#6CA1BF', '#ED6E85', '#F2A254', '#F8CE6B', '#F8EA6B', '#6CBDBF', '#84E97B', '#D8FFA6', '#736CBF', '#6C83BF'];
const candidateColor = '#e81E8B';
function normalizeValue(v) {
    if (v && v.value) {
        let result = +v.value;
        if (result < 0 || Number.isNaN(result)) result = 0;
        if (result > 1) result = 1;
        v.value = Math.floor(result * 100);
    }
}

export default {
    name: 'ComplianceDiagramm',
    components: {
      PieChart,
    },
    props: {
        mainPosition: Object,
        arrPositions: Array,
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters({
        results: 'users/candidate/results',
      }),
      candidatePosition() {
        if (!this.mainPosition) {
          return 0;
        }
        const ret = {
          name: this.mainPosition.name,
          value: this.mainPosition.value,
          color: candidateColor,
        };
        normalizeValue(ret);
        return ret;
      },
      positions() {
        const arr = this.arrPositions ? this.arrPositions.map((el, ind) => ({
          name: el.name,
          value: el.value,
          color: colors[ind % colors.length],
        })) : [];
        arr.forEach(normalizeValue);
        if (this.candidatePosition) {
          arr.push(this.candidatePosition);
        }
        arr.sort((a, b) => b.value - a.value);
        return arr;
      },
      filteredPositions() {
        return this.positions.filter((el) => el.name !== this.candidatePosition.name);
      },
      chartdata() {
        return {
          hoverBackgroundColor: 'red',
          hoverBorderWidth: 10,
          labels: this.positions.map((el) => el.name),
          datasets: [{
              label: 'Data One',
              backgroundColor: this.positions.map((el) => el.color),
              data: this.positions.map((el) => el.value),
              borderWidth: 3,
            }],
        };
      },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.legend__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
        width: 70%;

        padding-left: 22px;
        &:nth-child(2n) {
        // padding-left: 2rem;
        }
        &:nth-child(2n+1) {
        // padding-right: 2rem;
        }
}

.position-candidate {
    width: calc( 70% + 25px);
    border: 1px solid $color-blue;
    box-sizing: border-box;
    border-radius: 10px;
    background: $color-very-light-gray;
    padding: 1.5rem;
    font-size: $font-sm;
    &__number {
        font-weight: bold;
        color: $color-blue;
        margin-left: 2rem;
        font-size: 18px;
    }
}

@media (max-width: $break-point-sm) {
    .position-candidate {
      width: 100%;
    }
    .legend__item {
        width: calc(100% - 25px);
    }
}

</style>
