<template>
   <div>
     <PsychoReport v-if="ps_types.length===1" :ps="ps_types[0]" @loadPdf="$emit('loadPdf', $event)" />
     <v-tabs v-if="ps_types.length>1" v-model="tab" align-with-title ref="tabs">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="ps in ps_types" :key="ps.code" class="no-caps">{{ps.title}}</v-tab>
      </v-tabs>

      <v-tabs-items v-if="ps_types.length>1" v-model="tab">
          <v-tab-item v-for="ps in ps_types" :key="ps.code">
              <PsychoReport :ps="ps" @loadPdf="$emit('loadPdf', $event)" @switchTo="$emit('switchTo', $event)" />
          </v-tab-item>
      </v-tabs-items>

  </div>
</template>
<script>
/* eslint-disable */

import PsychoReport from '@/components/psycho/PsychoReport';

export default {
  components: {
    PsychoReport
  },
  props: {
    ps_types: Array,
    selectTab: String
  },
  watch: {
    selectTab() {
      if (this.selectTab) {
        const ind = this.ps_types.findIndex(el => el.title.toLowerCase() === this.selectTab.toLowerCase())
        if (ind >= 0) {
          this.tab = ind
          setTimeout(() => {
            this.$refs.tabs?.$el?.scrollIntoView({ behavior: 'smooth' });
          }, 700)
        }
      }
    } 
  },
  computed: {

  },
  data() {
    return {
      tab: 0,

    };
  },
};
</script>

<style lang="scss">
.no-caps {
  text-transform: none !important;
}


</style>
