<template>
  <v-tabs v-model="curTab">
    <v-tabs-slider color="primary"></v-tabs-slider>
    <v-tab v-for="tab in tabs" :key="tab.title" @click="setSelectedTab(tab)" >
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProfileTabs',
  data() {
    return {
      curTab: 0,
    };
  },
  watch: {
    selectedTab(v) {
      const ind = this.tabs.indexOf(v);
      if (ind >= 0) {
        this.curTab = ind;
      }
    },
  },
  computed: {
    ...mapGetters({
      tabs: 'profile/tabs/tabs',
      selectedTab: 'profile/tabs/selectedTab',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedTab: 'profile/tabs/setSelectedTab',
    }),
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: none;
  letter-spacing: normal;
}
</style>
