<template>
  <div v-if="results && (riskFactors || attentionFactor)">
    <div v-if="riskFactors && riskFactors.factors" class="row mt-2 mt-md-8">
      <div class="col-md-7">
        <h3 class="page-title d-inline-block relative">
          Факторы риска <span class="counter">{{ riskFactors.factors.length }}</span>
        </h3>

        <div v-if="!riskFactors.factors.length" class="page-description mt-4">Отсутствуют</div>

        <div v-for="(factor, ind) in riskFactors.factors" :key="ind">
          <div class="page-subtitle mt-4">{{factor.title}}</div>
          <div class="page-description mt-3">{{factor.description}}</div>
        </div>
      </div>
    </div>

    <div v-if="attentionFactor && attentionFactor.factors" class="row mt-2 mt-md-8">
      <div class="col-md-7">
        <h3 class="page-title d-inline-block relative ">
          Зоны внимания <span class="counter">{{attentionFactor.factors.length}}</span>
        </h3>

        <div v-if="!attentionFactor.factors.length" class="page-description mt-4">Отсутствуют</div>

        <div v-for="(factor, ind) in attentionFactor.factors" :key="ind">
          <div class="page-subtitle mt-4">{{factor.title}}</div>
          <div class="page-description mt-3">{{factor.description}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Risks',
  components: {
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    riskFactors() {
      return this.results.risk_factors;
    },
    attentionFactor() {
      return this.results.attantion_factor;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

h3 {
  font-size: 1.6rem;
}

</style>
