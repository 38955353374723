<template>
  <div v-if="results && stressTolerance">
    <div class="row mt-2 mt-md-8">

      <div class="col-md-4 col-xl-3">
        <h3 v-if="!hideTitle" class="page-title">Стрессоустойчивость</h3>

        <div class="stress-scale mx-auto mt-4 mt-md-8">
          <CircleScale :value="circleScaleValue" />
        </div>
      </div>

      <div class="col-md-8 col-lg-7 offset-lg-1">
        <hr class="d-md-none mt-2 mb-6">

        <div class="page-subtitle" :class="classTitle">{{stressToleranceName}}</div>
        <div class="page-description mt-2 mt-md-8">{{stressTolerance.description}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LEVELS } from '@/common/constants/levels';
import CircleScale from '@/components/ui/CircleScale';

export default {
  name: 'StressTolerance',
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CircleScale,
  },
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    stressTolerance() {
      return this.results.stress_tolerance;
    },
    circleScaleValue() {
      switch (this.stressTolerance.stress_tolerance_code) {
        case LEVELS.HEIGHT: return 100;
        case LEVELS.MEDIUM: return 50;
        default: return 0;
      }
    },
    classTitle() {
      switch (this.stressTolerance.stress_tolerance_code) {
        case LEVELS.HEIGHT: return 'success--text';
        case LEVELS.MEDIUM: return 'warning--text';
        default: return 'error--text';
      }
    },
    stressToleranceName() {
      switch (this.stressTolerance.stress_tolerance_code) {
        case LEVELS.HEIGHT: return 'Высокий уровень стрессоустойчивости';
        case LEVELS.MEDIUM: return 'Средний уровень стрессоустойчивости';
        default: return 'Низкий уровень стрессоустойчивости';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.stress-scale {
  max-width: 450px;
}
</style>
