<template>
  <div v-if="results">
    <div class="row mt-2 mt-md-8 pb-6 _border-bottom">
      <div class="col-12">
        <h3 class="profile-board__title">Отчет соответствия</h3>
      </div>
      <div class="col-md-9" v-if="loading.calcCompliance">
        <v-progress-circular :size="50" color="primary" class="text-center" indeterminate />
      </div>
      <div class="col-md-9" v-else-if="!compliances || !compliances.length">
         Нет данных
      </div>
      <div v-else class="col-md-9">
        <div v-for="(c, index) in compliances" :key="index" >
          <hr v-if="index" class="my-6">
          <h4 v-if="c.title" class="my-4">{{c.title}}</h4>
          <ComplianceDiagramm :arrPositions="c.positions" />
        </div>
        <!-- <ComplianceDiagramm :_mainPosition="compliance.candidate_position" :arrPositions="compliance.positions" /> -->
      </div>

      <div class="col-md-3" v-if="$store.getters['auth/isAllowed']('view_settingscompliencereport')">
        <SetupCompliance />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SetupCompliance from './SetupCompliance.vue';
import ComplianceDiagramm from './ComplianceDiagramm.vue';

export default {
  name: 'ComplianceReport',
  components: {
    ComplianceDiagramm, SetupCompliance,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
      loading: 'users/candidate/loading',
    }),
    compliances() {
      return this.results.compliances?.filter(el => {
        if (!el.positions || !el.positions.length) {
          return false;
        }
        const sum = el.positions.reduce((acc, pos) => acc + (Number.isNaN(+pos.value) ? 0 : +pos.value), 0);
        return sum > 0;
      });
    },
  },
};
</script>

<style scoped lang="scss">

h3 {
  font-size: 1.6rem;
}

</style>
