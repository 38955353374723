<template>
  <div class="donut">
    <div class="donut__number" :style="{color, fontSize}">{{value}}%</div>
    <svg width="100%" height="100%" viewBox="0 0 36 36">
      <circle class="donut-hole" cx="18" cy="18" r="15.91549430918954" fill="#fff"></circle>
      <circle class="donut-ring" cx="18" cy="18" r="15.91549430918954" fill="transparent"
              stroke="#DEE1EA" stroke-width="1"></circle>
      <circle class="donut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent"
              :stroke="color" stroke-width="1" :stroke-dasharray="progress" stroke-dashoffset="25"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DonutScale',
  props: {
    value: {
      type: Number,
      required: true,
    },
    fontSize: {
      type: String,
      default: '3rem',
    },
  },
  data: () => ({
    progress: '0 100',
  }),
  computed: {
    color() {
      if (this.value > 0 && this.value < 25) {
        return '#FF6E6D';
      }
      if (this.value >= 25 && this.value < 50) {
        return '#FF9900';
      }
      if (this.value >= 50 && this.value < 75) {
        return '#FFCD1A';
      }
      if (this.value >= 75) {
        return '#94D727';
      }
      return '#DEE1EA';
    },
  },
  mounted() {
    setTimeout(() => {
      this.progress = `${this.value} ${100 - this.value}`;
    }, 0);
  },
};
</script>

<style scoped lang="scss">
.donut {
  position: relative;
  &__number {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // font-size: 3rem;
    font-weight: bold;
    // line-height: 100%;
  }
  &-segment {
    transition: stroke-dasharray 1s;
  }
}
</style>
