<template>
    <div class="profile-board test-editor-results">
      <h2 v-if="!results && (loading.results || loading.candidate)">Загрузка...</h2>
      <h2 v-else-if="!results">Тест не завершен</h2>
      <div v-if="results">

        <h1 class="rep-top-title">Профессиональный навигатор</h1>

        <div v-for="(testData, key) in results" :key="key" class="row mt-2 mt-md-8">
            <div class="col-12 pa-3">
                <h3 class="rep-test-title">{{ getTestTitle(key) }}</h3>
                <hr class="mt-7 mb-4">
                <div v-if="testData">

                    <!-- <pre>{{ tabInfo.testData }}</pre> -->
                    <div class="profnavigator-list">
                        <div v-for="(prof, indProf) in positions(testData)" :key="indProf" class="profnavigator-item">
                            <div v-for="block in 10" :key="block" class="profnavigator-item-block"
                                :class="getBlockClass(prof, indProf, block)" />
                            <div class="profnavigator-item-value">{{ getProfValue(prof) }}</div>
                            <div class="profnavigator-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        </div>
                    </div>
                    <h4 class="desc-title">Описание</h4>
                    <p>Ниже представлены 3 ключевых направления деятельности, к которым Вы проявили наибольший интерес. Развиваясь в данных направлениях, Вы можете добиться большего успеха, так как данные направления деятельности Вас будут мотивировать в Вашей работе
                        Для успешного освоения профессий Вам может понадобиться пройти дополнительное обучение, обратитесь за информацией к консультанту или работодателю.
                    </p>
                    <div v-for="(prof, indProf) in top3positions(testData)" :key="indProf" class="desc-item">
                        <div class="desc-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        <div class="desc-item-text" v-html="prepareText(prof.text)" />
                    </div>
                </div>
            </div>
        </div>

      </div>

    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            res: 'users/candidate/results',
            loading: 'users/candidate/loading',
            loadingCandidate: 'users/candidate/loading',
        }),

        results() {
            return this.res?.constructor_test_results
        },
        candidateId() {
            return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
        },
    },
    methods: {
        ...mapActions({
            loadCandidatePdf: 'users/candidate/loadCandidatePdf',
        }),
        getTestTitle(key) {
            const testDesc = this.res?.general_info?.tests.find(el => el.test_code===key)
            return testDesc ? testDesc.title : "Профнавигатор"
        },
        positions(testData) {
            const arr = [...testData]
            arr.sort((a, b) => b?.value - a?.value)
            return arr
        },
        top3positions(testData) {
            return this.positions(testData).slice(0, 3)
        },
        getProfTestTitle(key) {
            const testDesc = this.res?.general_info?.tests.find(el => el.test_code===key)
            return testDesc ? testDesc.title : "Профнавигатор"
        },
        getProfTests(key) {
            this.res?.constructor_test_results[key].sort((a, b) => b?.value - a?.value)
            return this.res?.constructor_test_results[key].slice(0, 3)
        },
        getProfValue(item) {
            let v = Math.round(item.value)
            if (v < 0) v = 0;
            if (v > 10) v = 10;
            return v
        },
        getBlockClass(prof, indProf, indBlock) {
            const v = this.getProfValue(prof)
            if (indBlock <= v) return indProf === 0 ? 'profnavigator-item-block-dark' : 'profnavigator-item-block-blue'
            return 'profnavigator-item-block'
        },
        prepareText(text) {
            const lines = text.split('\n');
            let html = '';
            lines.forEach(line => {
                const spaces = line.match(/^\s*/)[0].length;
                const indent = '&nbsp;&nbsp;'.repeat(spaces);
                html += `<p>${indent}${line.trim()}</p>`;
            });

            return html;
        }

    },
};
</script>


<style scoped lang="scss">

.rep-top-title {
  font-size: 28px;
  color: #4f62a4;
}

.rep-test-title  {
  font-size: 24px;
}


</style>
