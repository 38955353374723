<template>
  <div v-if="results && (scales || peakScales)">
    <template v-if="peakScales && peakScales.scales.length">
      <!-- <hr class="mt-8"> -->
      <div class="row">
        <div class="col-md-8 mt-8">
          <div class="mr-md-10">
            <h3 class="page-title d-inline-block relative">
              Поведение <span class="counter">{{peakScales.scales.length}}</span>
            </h3>

            <div v-for="(scale, ind) in peakScales.scales" :key="ind">
              <div class="page-subtitle mt-4">{{scale.title_start}}/{{scale.title_end}}</div>
              <div class="page-description mt-3">{{scale.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PeakScales',
  components: {
  },
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    scales() {
      return this.results.scales.scales;
    },
    scalesResults() {
      return this.scales.scales
        .map((s) => ({
          titleStart: s.title_start, titleEnd: s.title_end, value: s.value,
        }));
    },
    peakScales() {
      return this.results.scales.peak_scales;
    },
    credibility() {
      return this.scales.scales.filter((s) => s.description);
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/variables";
.page-description.font-italic {
  font-size: $font-xs;
  @media (min-width: $break-point-sm) {
    font-size: $font-sm;
  }
}
</style>
