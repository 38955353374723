<template>
  <div v-if="results && (scales || peakScales)">

    <template v-if="scales && scales.scales && scales.scales.length">
      <!-- <hr class="mt-4"> -->
      <div class="row">
        <div class="col-md-8 mt-8">
          <div class="mr-md-10">
            <h3 class="page-title">Достоверность</h3>

            <div v-if="results.general_info.reliability === 0" class="reliability reliability-0">Не достоверный</div>
            <div v-if="results.general_info.reliability === 1" class="reliability reliability-1">Сомнительно достоверный</div>
            <div v-if="results.general_info.reliability === 2" class="reliability reliability-2">Достоверный</div>

            <div v-for="(scale, ind) in credibility" :key="ind">
              <div class="page-subtitle mt-4">{{scale.title_start}}/{{scale.title_end}}</div>
              <div class="page-description mt-3">{{scale.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Credibility',
  components: {
  },
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    scales() {
      return this.results && this.results.scales ? this.results.scales.scales : 0;
    },
    scalesResults() {
      return this.scales ? this.scales.scales.map((s) => ({
        titleStart: s.title_start, titleEnd: s.title_end, value: s.value,
      })) : [];
    },
    peakScales() {
      return this.results && this.results.scales ? this.results.scales.peak_scales : 0;
    },
    credibility() {
      return this.scales?.scales.filter((s) => s.description);
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/variables";
.page-description.font-italic {
  font-size: $font-xs;
  @media (min-width: $break-point-sm) {
    font-size: $font-sm;
  }
}

.reliability {
    padding: 4px 16px;
    display: inline-block;
    margin: 10px 0;
}
.reliability-0 {
    background: #ffe4e4;
    border: 1px solid  #FF6E6D;
    color: #FF6E6D;
}
.reliability-1 {
    background: #fefdec;
    border: 1px solid #dbcc05;
    color: #dbcc05;
}
.reliability-2 {
    background: #F0FFC3;
    border: 1px solid #94D727;
    color: #94D727;
}

</style>
