<template>
  <div>
    <div class="psycho">
      <!-- <PdfButton v-if="showPdf && $store.getters['auth/isAllowed']('download_psychotypereport')"
        :loading="loadingCandidate.pdf.psycho" class="load-pdf" @downloadPdf="$emit('loadPdf', ps, $event)"
      /> -->
      <h1 class="psycho-title" id="topPsycho">{{ ps.title }} тип личности</h1>
      <div class="section">
        <span class="text-bold marker">Ваш девиз: </span>{{ ps.motto }}
      </div>

      <!-- 62-2 в ПДФ и вебке скрыть раздел "яркие представители" вместе с их картинками -->
      <!-- <div class="section">
        <span class="text-bold marker">Яркие представители: </span>{{ ps.people }}
        <div  class="row-images">
          <img v-for="(img, ind) in ps.people_images" :key="ind" :src="getImage(img)">
        </div>
      </div> -->

      <div class="section">
        <div class="title marker">Описание характера и поведения</div>
        <div v-html="ps.desc"></div>
      </div>

      <div class="section">
        <div class="title marker">Сильные стороны:</div>
        <ul>
          <li v-for="(s, ind) in ps.power_sides" :key="ind">{{ s }}</li>
        </ul>
      </div>

      <div class="section">
        <div class="title marker">Слабые стороны:</div>

        <div v-for="(s, ind) in ps.weak_sides" :key="ind" class="expansion">
            <div>
              <ul><li>{{ s.text }}</li></ul>
              <v-expand-transition>
                <div v-if="isPanelOpened(ind)" class="bg-blue expansion-content">
                  <span class="text-blue">Рекомендация:</span> {{ s.recommend }}
                </div>
              </v-expand-transition>
            </div>
            <div>
                <v-btn class="ml-5" color="primary" :outlined="!isPanelOpened(ind)" @click="togglePanel(ind)">
                  Рекомендация
                  <v-icon right :class="{'opened': isPanelOpened(ind)}">mdi-chevron-down</v-icon>
                </v-btn>
            </div>
        </div>
      </div>

      <div class="section">
        <div class="title marker">
          Особенности в коммуникации и установлении социальных контактов:
        </div>
        <p v-html="ps.features"></p>

        <div class="row">
          <div class="col-6">
            <div>
              <span class="text-blue">Легче</span> устанавливать коммуникацию:
            </div>
            <div v-if="ps.features_easy">
              <template v-for="(item, ind) in ps.features_easy">
                  <span v-if="ind" :key="ind">, </span>
                    <span :key="-ind" class="psycho-link" @click="$emit('switchTo', item)">{{item}}</span>
              </template>
              тип личности
            </div>
          </div>
          <div class="col-6">
            <div class="border-left">
              <div>
                <span class="text-blue">Сложнее</span> устанавливать коммуникацию:
             </div>
              <div v-if="ps.features_complicated">
                <template v-for="(item, ind) in ps.features_complicated">
                  <span v-if="ind" :key="ind">, </span>
                    <span :key="-ind" class="psycho-link" @click="$emit('switchTo', item)">{{item}}</span>
                </template>
                тип личности
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="title marker">
          Рекомендации для эффективной коммуникации:
        </div>
        <div v-for="(rec, ind) in ps.recommendations" :key="ind" class="_subsection">
          <div class="subtitle">
            {{ ind + 1 }}. {{ rec.title }}
          </div>
          <div class="bg-blue subsection py-3">
            <ul>
              <li v-for="(rec_item, rec_ind) in rec.items" :key="rec_ind">
                {{ rec_item }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="title marker">
          Подходящие направления деятельности:
        </div>
        <ul>
          <li v-for="(s, ind) in ps.directions" :key="ind">{{ s }}</li>
        </ul>
      </div>

      <div class="section">
        <div class="title marker">
          Факторы, усиливающие мотивацию (продуктивность):
        </div>
        <div v-for="(prod, ind) in ps.productivity" :key="ind" class="_subsection">
          <div class="subtitle">
            {{ prod.title }}
          </div>
          <div class="bg-blue subsection">
            {{ prod.text }}
          </div>
        </div>
      </div>
    </div>

    <!-- <TimeScale :data="dt" /> -->

  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import TimeScale from '@/components/ui/TimeScale'
import PdfButton from '@/components/ui/PdfButton';

export default {
  components: {
    TimeScale, PdfButton
  },
  props: {
    ps: Object,
    showPdf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panelsOpened: [],

      dt: {
        value: 40,
        description: " wsdasdasdasd",
        title: "qwedqwe",
        markers: [
          { text: "Старт", value: 0 },
          { text: "Психологический тест", value: 30 },
          { text: "Профессиональные интересы", value: 70 },
          { text: "Консультация с экспертом", value: 100 },
        ]
      }

    };
  },
  computed: {
    ...mapGetters({
      loadingCandidate: 'users/candidate/loading',
    }),
  },
  methods: {
    getImage(img) {
      return window.location.host.startsWith('localhost') ? 'https://dev.sostaff.io'+img : img
    },
    isPanelOpened(id) {
      return this.panelsOpened.includes(id)
    },
    togglePanel(id) {
      if (!this.panelsOpened.includes(id))
        this.panelsOpened.push(id)
      else
        this.panelsOpened = this.panelsOpened.filter(item => item != id);
    }

  }
};
</script>

<style lang="scss">
.no-caps {
  text-transform: none;
}

h1.psycho-title {
  margin: 30px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
}

.psycho {
  position: relative;
  max-width: 1024px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.87);

  .load-pdf {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .opened {
    transform: rotate(180deg);
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
  }

  .text-bold {
    font-weight: 600;
  }

  .bg-blue {
    background-color: #f5f8ff;
  }

  .text-blue {
    color: #18a0fb;
  }

  .psycho-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .marker {
    position: relative;
  }

  .marker:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -20px;
    width: 8px;
    height: 8px;
    border: 3px solid #18a0fb;
    display: inline-block;
    transform: rotate(45deg);
    margin-right: 5px;
  }

  .section {
    position: relative;
    margin: 20px 20px 20px 20px;
    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      margin-bottom: 16px;
      &.marker:before {
        top: 9px;
      }
      &:after {
        content: "";
        width: 100px;
        border-bottom: 3px solid #18a0fb;
        display: block;
        margin-top: 12px;
      }
    }

    .subtitle {
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 10px;
    }
    ul {
      padding-left: 16px;
      li {
        margin: 5px 0;
        line-height: 140%;
      }
    }

    .subsection {
      margin: 20px 0;
      padding: 20px;
    }

    // .expansion {
    //   position: relative;
    //   text-align: left;
    //   padding: 0 20px 0 0;
    // }

    .expansion-content {
      padding: 16px;
    }

    .border-left {
       border-left: 3px solid #18a0fb;
       padding-left: 20px;
    }

    .row-images {
      display: flex;
      img {
        height: 200px;
        margin: 20px 10px;
      }
    }

    .expansion {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

  }
}
</style>
